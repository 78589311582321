import React from "react"
import placeholder from "../../images/logo.png"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import { PrimaryButton } from "../buttons"

const Date = styled.div`
  background: #15354c;
  color: white;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 400;
  z-index: 1;
  position: absolute;
  top: 0;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }

  @media (max-width: 450px) {
    font-size: 1rem;
    padding: 0.5rem;
  }
`

const Card = styled.div`
  background-color: #e8ebed;
  display: flex;
  font-family: roboto;
  position: relative;
  overflow: hidden;
  padding: 3rem 0;
  align-items: flex-start;
  border-bottom: 1px dashed gray;
  @media (max-width: 768px) {
    display: block;
    padding-top: 0;
    text-align: center;
    border-bottom: none;
  }
`
const Content = styled.div`
  display: block;
  color: #333;
  z-index: 100;
  font-family: roboto;
  padding: 0 2rem;
  flex: 1 0;
  text-align: left;

  h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  button {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 2rem;
    p {
      text-align: left;
    }
  }
`
const Image = styled.div`
  max-height: 400px;
  flex: 0 0 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`
const ArticleCard = ({ title, createdAt, coverPhoto, content }) => {
  const date = createdAt //TODO:// need to update old DB values.
  return (
    <Card>
      <Image>
        <Date>{moment(date).format("MM-DD-YYYY")}</Date>
        {coverPhoto && <Img fixed={coverPhoto.childImageSharp.fixed} />}
        {!coverPhoto && <img src={placeholder} />}
      </Image>
      <Content>
        <h2>{title}</h2>

        <ReactMarkdown
          escapeHtml={false}
          source={content.substring(0, 400) + "..."}
        />
        <div style={{ textAlign: "center" }}>
          <PrimaryButton>Read more</PrimaryButton>
        </div>
      </Content>
    </Card>
  )
}

export default ArticleCard
