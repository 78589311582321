import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import FluidContainer from "../components/fluidContainer"
import ArticleCard from "../components/Cards/article"
import styled from "@emotion/styled"
import ContentContainer from "../components/contentContainer"
import { Header1, Paragraph } from "../components/headings"
import Banner from "../components/banner"
import { PrimaryButton } from "../components/buttons"
import SEO from "../components/seo"

const ArticlesList = styled.ul`
  justify-content: space-between;
  margin: 0;
  li {
    width: 100%;
    list-style: none;
    margin-bottom: 0;
    a {
      text-decoration: none;
    }
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const ArticlesPage = ({ data }) => {
  return (
    <Layout>
      <FluidContainer>
        <SEO title="News" keywords={[`eating`, `contests`, `official`]} />
        <Banner img={data.placeholderImage.childImageSharp.fluid}>
          <Header1>News and articles</Header1>
          <Paragraph>
            Stay up to date with the latest news from the finest journalists
            here at Major League Eating. Integrity, curiousity, gastronomy are
            our values. Our mission - to help you make sense of all of this.
          </Paragraph>
        </Banner>
        <ContentContainer>
          <ArticlesList>
            {data.allStrapiArticles.edges.map(document => (
              <li key={document.node.id}>
                <Link to={`/articles/${document.node.sn}`}>
                  <ArticleCard {...document.node} />
                </Link>
              </li>
            ))}
          </ArticlesList>
          <div style={{ margin: "2rem auto", textAlign: "center" }}>
            <Link to="/articles/all">
              <PrimaryButton>View more</PrimaryButton>
            </Link>
          </div>
        </ContentContainer>
      </FluidContainer>
    </Layout>
  )
}

export default ArticlesPage

export const pageQuery = graphql`
  query ArticlesQuery {
    allStrapiArticles(limit: 6, sort: { fields: [createdAt], order: [DESC] }) {
      edges {
        node {
          sn
          title
          createdAt
          coverPhoto {
            childImageSharp {
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          content
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "news-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
